@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html, body {
        font-family: 'Manrope';
        background-color: black;
        overflow-x: hidden;
    }    

    
    .ellipsis {
      position: absolute;
      top: 100px;
      left: -100px;
      width: 400px;
      height: 400px;
      background: 
      radial-gradient(
        50% 70% at 50% 42.42%,
        #98144e 0%,
        rgba(225, 190, 102, 0) 63.22%
      ),
      radial-gradient(
        40% 60% at 25% 50%,
        #211b3d 0%,
          rgba(0, 0, 0, 0) 75%
        ),
        radial-gradient(
          60% 60% at 50% 50%,
          rgb(29, 52, 58) 0%,
          rgba(29, 52, 58, 0) 40%
        );
      transform: rotate(33deg);
      opacity: 0.8;
      z-index: -1;
    }

    .gradient-bg {
      background: radial-gradient(circle at bottom,  rgba(172, 127, 244, 0.5) 0%, #000000 30%) ;
  }
    
    .ellipsis.ellipsis-purple {
      top: 1350px;
      left: -250px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(172, 127, 244, 0.5) 0%,
        rgba(21, 25, 52, 0) 100%
      );
      transform: rotate(-5deg);
    }

    .ellipsis.ellipsis-blue {
      top: 2350px;
      left: -250px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(20, 105, 144, 0.5) 0%,
        rgba(21, 25, 52, 0) 100%
      );
      transform: rotate(-5deg);
    }

    .ellipsis.ellipsis-yellow {
      top: 4550px;
      left: -250px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(175, 146, 38, 0.8) 0%,
        rgba(21, 25, 52, 0) 100%
      );
      transform: rotate(-5deg);
    }
    
    @media screen and (min-width: 768px) {
      .ellipsis {
        position: absolute;
        top: 90px; 
        left: 38%;
        width: 50%;
        height: 50%;
        transform: rotate(53deg);
      }
      .ellipsis.ellipsis-purple {
        top: 1350px;
        right: 0px;
      }
    }
}
