.carousel {
  position: relative;
  transform-style: preserve-3d;
}

.card-container {
  position: absolute;
  height: 100%;
  transform: rotateY(calc(var(--offset) * 50deg)) scaleY(calc(1 + var(--abs-offset) * -0.3))
    translateZ(calc(var(--abs-offset) * -60rem)) translateX(calc(var(--direction) * -16rem));
  filter: blur(calc(var(--abs-offset) * 0.2rem));
  transition: all 0.3s ease-out;
}

.nav {
  z-index: 2;
  cursor: pointer;

  &.left {
    transform: translateX(-100%) translatey(-50%);
  }

  &.right {
    right: 0;
    transform: translateX(100%) translatey(-50%);
  }
}
